export const TEXTS = {
  LOGIN_ENDPOINT: "1.0.0/users/login",
  LINK_ENDPOINT: "1.0.0/links",
  SEARCH_INPUT_PLACEHOLDER: "Keresés a projektek között",
  CREATE_MODAL_NAME: "Létrehozás",
  MODIFY_MODAL_NAME: "Módosítás",
  MODAL_PROJECT_NAME: "Project neve",
  MODAL_GOOGLE_PLAY: "Google Play:",
  MODAL_APP_STORE: "App store:",
  MODAL_PC_DESKTOP: "PC desktop:",
  CANCEL: "Mégsem",
  SAVE: "Mentés",
  POPUP_DELETE_LINK_TEXT: "Biztosan törölni akarod ezt a linket?",
  QR: "QR kód",
  DOWNLOAD_LINK: "Letöltő link",
  PUBLICATED_LINKS: "Publikált app linkek",
  GOOGLE_PLAY_URL: "Google Play URL:",
  APPLE_STORE_URL: "Apple Store URL:",
  PC_DESKTOP_URL: "PC desktop URL:",
  LOGIN_EMAIL_PLACEHOLDER: "e-mail cím",
  LOGIN_PASSWORD_PLACEHOLDER: "jelszó",
  LOGIN_TITLE: "Appentum link generátor",
  LOGIN_POPUP_ERROR_MESSAGE: "Hibás email / jelszó",
  TOO_MANY_LOGIN_ATTEMPTS: "Túl sok hibás próbálkozás! Hátralévő idő:",
  SITE_NOT_FOUND_TITLE: "Az oldal amit keresel nem található.",
  SITE_NOT_FOUND_LINK: "Menj vissza a főoldalra.",
  YES: "Igen",
  NO: "Nem",
  DOWNLOAD: "Letöltés",
  LINKS_TABLE_POPUP_MESSAGE: "Link sikeresen másolva!",
  LOGOUT: "Kijelentkezés",
  LOADING: "Töltés...",
  LOGIN: "Belépés",
  CHARACTER_LENGTH: "Hátralévő karakterek száma:",
  NO_LINKS_ADDED_YET: "Nincs hozzáadott tartalom",
};
