import { createSlice } from "@reduxjs/toolkit";

const qrCodeSlice = createSlice({
  name: "QRCode",
  initialState: {
    qrCodes: [],
  },
  reducers: {
    addQRCode: (state, action) => {
      state.qrCodes.push({ qrCode: action.payload.qrCode, id: action.id });
      state.qrCodes = Array.from(
        new Set(state.qrCodes.map((qrCode) => qrCode.qrCode))
      ).map((qrCode) => state.qrCodes.find((item) => item.qrCode === qrCode));
      state.qrCodes.sort((a, b) => a.id - b.id);
    },
    removeQRCodes: (state, action) => {
      state.qrCodes = [];
    },
  },
});
export const { addQRCode, removeQRCodes } = qrCodeSlice.actions;
export default qrCodeSlice.reducer;
