import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { getAllLink } from "../../actions/link";
import linkReducer, { linkInitialState } from "../../reducer/link";
import axios from "axios";
import HttpService from "../../services/http";
import LinkTable from "./table/table";
import LinkPagination from "./pagination";
import LinkModal from "./modal/modal";
import PopupStyle from "../../constants/popup/popup";
import { TEXTS } from "../../constants/text";
import {
  useAppleUrlChange,
  useDesktopUrlChange,
  useDownloadUrlChange,
  useGoogleUrlChange,
  useMessageChange,
  useNameChange,
  useSearchNameChange,
  useSelectedIdChange,
} from "./linkUtils";
import LinkHeader from "./molecules/linkHeader";
import LinkSearchBar from "./molecules/linkSearchbar";
import { addQRCode, removeQRCodes } from "../../constants/qrCode/qrCodeRedux";

const Links = () => {
  const token = useSelector((state) => state.loginReducer.token);
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);
  HttpService.setAxiosDefaults(token);
  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const functionRan = useRef(false);
  const searchNameChangeRef = useRef("");
  const [page, setPage] = useState(1);
  const [modify, setModify] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const links = useSelector((state) => state.linkReducer.links);
  const propSaveStatus = useSelector((state) => state.linkReducer.saveStatus);
  const message = useSelector((state) => state.linkReducer.message);
  const qrCodes = useSelector((state) =>
    state.qrCode.qrCodes.map((item) => item.qrCode)
  );
  const [state, reducerDispatch] = useReducer(linkReducer, linkInitialState);
  const {
    name,
    downloadUrl,
    googlePlayUrl,
    appleStoreUrl,
    pcDesktopUrl,
    selectedId,
    searchName,
    errorMessage,
  } = state;

  useEffect(() => {
    if (functionRan.current === false) {
      dispatch(getAllLink(1));
    }
    return () => {
      functionRan.current = true;
    };
  }, [dispatch]);

  const qrImage = useCallback(
    async (id) => {
      try {
        await axios.get(`${TEXTS.LINK_ENDPOINT}/${id}`).then((response) => {
          dispatch(addQRCode({ qrCode: response.data, id: id }));
        });
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(removeQRCodes());
    links.links?.forEach((link) => qrImage(link.id));
  }, [dispatch, links, qrImage]);

  const handleNameChange = useNameChange(reducerDispatch);
  const handleDownloadUrlChange = useDownloadUrlChange(reducerDispatch);
  const handleGooglePlayUrlChange = useGoogleUrlChange(reducerDispatch);
  const handleAppleStoreUrlChange = useAppleUrlChange(reducerDispatch);
  const handlePcDesktopUrlChange = useDesktopUrlChange(reducerDispatch);
  const handleSelectedIdChange = useSelectedIdChange(reducerDispatch);
  const setSearchName = useSearchNameChange(reducerDispatch);
  const setMessage = useMessageChange(reducerDispatch);

  useEffect(() => {
    if (message.data) {
      setMessage(message?.data[0]?.errors[0]);
      setIsPopupVisible(true);
    }
  }, [message, setMessage]);

  const handleCloseModal = useCallback(() => {
    setModify(false);
    setModalVisible(false);
    handleNameChange("");
    handleDownloadUrlChange("");
    handleGooglePlayUrlChange("");
    handleAppleStoreUrlChange("");
    handlePcDesktopUrlChange("");
    handleSelectedIdChange(null);
    setMessage("");
  }, [
    handleNameChange,
    handleDownloadUrlChange,
    handleGooglePlayUrlChange,
    handleAppleStoreUrlChange,
    handlePcDesktopUrlChange,
    handleSelectedIdChange,
    setMessage,
  ]);

  useEffect(() => {
    if (propSaveStatus) {
      handleCloseModal();
    }
  }, [propSaveStatus, handleCloseModal]);

  useEffect(() => {
    if (searchNameChangeRef.current !== searchName) {
      setPage(1);
      searchNameChangeRef.current = searchName;
    }
  }, [searchName]);

  const search = useCallback(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      dispatch(getAllLink(page, searchName));
    }, 150);
  }, [dispatch, page, searchName]);

  useEffect(() => {
    if (propSaveStatus) {
      setMessage("");
    }
  }, [propSaveStatus, setMessage]);

  useEffect(() => {
    if (propSaveStatus && !errorMessage) {
      dispatch(getAllLink(page));
    } else if (page && !errorMessage) {
      search();
    }
  }, [propSaveStatus, dispatch, page, search, errorMessage]);

  return (
    <div className="linkSite">
      <LinkHeader />
      <div className="linkContentBody">
        <LinkSearchBar
          setSearchName={setSearchName}
          searchName={searchName}
          setModalVisible={setModalVisible}
        />
        <LinkTable
          links={links}
          setModify={setModify}
          setModalVisible={setModalVisible}
          handleSelectedIdChange={handleSelectedIdChange}
          handleNameChange={handleNameChange}
          handleDownloadUrlChange={handleDownloadUrlChange}
          handleGooglePlayUrlChange={handleGooglePlayUrlChange}
          handleAppleStoreUrlChange={handleAppleStoreUrlChange}
          handlePcDesktopUrlChange={handlePcDesktopUrlChange}
          setIsPopupVisible={setIsPopupVisible}
          setMessage={setMessage}
          qrCodes={qrCodes}
        />
      </div>
      <LinkPagination links={links} setPage={setPage} page={page} />
      <LinkModal
        modalVisible={modalVisible}
        modify={modify}
        name={name}
        handleNameChange={handleNameChange}
        downloadUrl={downloadUrl}
        handleDownloadUrlChange={handleDownloadUrlChange}
        googlePlayUrl={googlePlayUrl}
        handleGooglePlayUrlChange={handleGooglePlayUrlChange}
        appleStoreUrl={appleStoreUrl}
        handleAppleStoreUrlChange={handleAppleStoreUrlChange}
        pcDesktopUrl={pcDesktopUrl}
        handlePcDesktopUrlChange={handlePcDesktopUrlChange}
        handleCloseModal={handleCloseModal}
        selectedId={selectedId}
      />
      <PopupStyle
        message={errorMessage}
        isPopupVisible={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
      />
    </div>
  );
};

export default Links;
