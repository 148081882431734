import { TEXTS } from "../../../constants/text";
import LinkRow from "./molecules/linkTableRow";
import Text from "./atoms/tableText";
import TableHeader from "./atoms/tableHeader";
const LinkTable = (props) => {
  const {
    links,
    setModify,
    setModalVisible,
    handleSelectedIdChange,
    handleNameChange,
    handleDownloadUrlChange,
    handleGooglePlayUrlChange,
    handleAppleStoreUrlChange,
    handlePcDesktopUrlChange,
    setIsPopupVisible,
    setMessage,
    qrCodes,
  } = props;

  const handleDownload = (base64String, fileName) => {
    const link = document.createElement("a");
    link.href = base64String;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setIsPopupVisible(true);
    setMessage(TEXTS.LINKS_TABLE_POPUP_MESSAGE);
  };

  const handleShowData = (record) => {
    setModify(true);
    setModalVisible(true);
    handleSelectedIdChange(record.id);
    handleNameChange(record.name);
    handleDownloadUrlChange(record.downloadLink.split("/").pop());
    handleGooglePlayUrlChange(record.googlePlayLink);
    handleAppleStoreUrlChange(record.appleStoreLink);
    handlePcDesktopUrlChange(record.pcDesktopLink);
  };
  return (
    <div className="linkTableLocation">
      <table className="table">
        <thead className="linkTable">
          <tr className="linkTableheader">
            <TableHeader children={TEXTS.MODAL_PROJECT_NAME} />
            <TableHeader children={TEXTS.QR} />
            <TableHeader children={TEXTS.DOWNLOAD_LINK} />
            <TableHeader children={TEXTS.PUBLICATED_LINKS} />
            <TableHeader children={null} />
            <TableHeader children={null} />
          </tr>
        </thead>
        {links.links?.length > 0
          ? links.links?.map((link, index) => (
              <tbody className="linkTableBody">
                <LinkRow
                  key={link.id}
                  link={link}
                  qrCode={qrCodes[index]}
                  onCopy={handleCopy}
                  onEdit={handleShowData}
                  onDownload={handleDownload}
                />
              </tbody>
            ))
          : links.links?.length === 0 && (
              <div className="noLinksMessage">
                <Text children={TEXTS.NO_LINKS_ADDED_YET} />
              </div>
            )}
      </table>
    </div>
  );
};

export default LinkTable;
